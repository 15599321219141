<template>
  <div class="confirm-points-donation">
    <category-block :category="category" position="position1" />
    <v-container>
      <CategoryTitle :category="category" />

      <v-card color="grey lighten-3">
        <v-card-text>
          <ResponseMessage :response="responseMessage" />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            large
            depressed
            color="primary"
            min-width="200"
            :to="{ name: 'Donate' }"
            >{{ $t("confirmPointsDonation.donateOtherPointsBtn") }}</v-btn
          >
          <!-- <v-btn
            large
            depressed
            color="primary"
            min-width="200"
            :to="{ name: 'Home' }"
            >{{ $t("confirmPointsDonation.goToHomeBtn") }}</v-btn
          > -->
        </v-card-actions>
      </v-card>
    </v-container>
    <category-block :category="category" position="position1" />
  </div>
</template>
<style scoped lang="scss"></style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import ResponseMessage from "@/components/ResponseMessage";

import categoryMixins from "~/mixins/category";

import customService from "@/service/customService";

export default {
  name: "ConfirmPointsDonation",
  mixins: [categoryMixins],
  components: {
    CategoryTitle,
    ResponseMessage
  },
  data() {
    return {
      token: null,
      loading: true,
      responseMessage: {}
    };
  },
  computed: {},
  methods: {
    async confirmPointsDonation(token) {
      try {
        this.responseMessage = {};
        if (token) {
          this.loading = true;
          let result = await customService.confirmPointsDonation(token);
          if (result && result.response) {
            this.responseMessage = result.response;
          } else {
            this.responseMessage = {
              errors: [
                {
                  error: this.$t("confirmPointsDonation.genericError")
                }
              ]
            };
          }
        }
      } catch (err) {
        console.log(err);
        this.responseMessage = {
          errors: [
            {
              error: this.$t("confirmPointsDonation.genericError")
            }
          ]
        };
      }
    }
  },
  mounted() {
    if (this.$route.query && this.$route.query.token) {
      this.token = this.$route.query.token;
      this.confirmPointsDonation(this.token);
    }
  }
};
</script>
